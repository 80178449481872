<template>
    <div class="content">
        <div class="row">

            <div class="col-md-12">
                <vuestic-widget :headerText="'Bienvenido '+me.name">
                    <div v-if="me.rol.id === 2 && isEmpty(company)">
                        <h3>Listado de compañías del cliente </h3>
                    </div>
                    <div v-if="me.rol.id === 3 || me.rol.id === 2 && !isEmpty(company)">

                        <AnalyticEventResume></AnalyticEventResume>
                    </div>

                </vuestic-widget>
            </div>
            <div class="col-md-12">
                <div v-if="me.rol.id === 3 || me.rol.id === 2 && !isEmpty(company)">
                    <vuestic-tabs class="tabs" :names="['Creados', 'Lanzados','Cerrados']">
                        <div slot="Creados">
                            <TableEventCreated></TableEventCreated>
                        </div>
                        <div slot="Lanzados">
                            <TableEventLaunched></TableEventLaunched>
                        </div>
                        <div slot="Cerrados">
                            <TableEventClosed></TableEventClosed>
                        </div>
                    </vuestic-tabs>
                </div>

                <div v-if="me.rol.id === 2 && isEmpty(company)">

                    <table-company></table-company>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import TableEventCreated from '../tables/TableEvent/TableEventCreated.vue'
    import TableEventLaunched from '../tables/TableEvent/TableEventLaunched.vue'
    import TableEventClosed from '../tables/TableEvent/TableEventClosed.vue'
    import TableCompany from '../tables/TableCompany/TableCompany.vue'
    import AnalyticEventResume from '../analytics/AnalyticEventResume.vue'
    import {SpringSpinner} from 'epic-spinners'
    import {
        mapState,
        mapActions
    } from 'vuex'

    export default {
        name: 'Table',
        components: {
            SpringSpinner,
            TableEventCreated,
            TableEventLaunched,
            TableEventClosed,
            TableCompany,
            AnalyticEventResume
        },
        data() {
            return {}
        },
        computed: {
            ...mapState({
                me: state => state.auth.me,
                company: state => state.auth.company,
            }),

        },
        mounted() {


        },
        methods: {
            ...mapActions([]),
            newEvent() {
                this.$router.push('event/new')
            },
            isEmpty(obj) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key))
                        return false;
                }
                return true;
            }

        }
    }
</script>

<style lang="scss">

    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }

    .labelBigAnalytic {
        font-size: 36px;
    }

    .labelMediumAnalytic {
        font-size: 22px;
    }
</style>
