export default {
    tableFields:   [
        {
            name:      '__component:badge-column',
            title:     '',
            dataClass: 'text-center',
            width:     '4%'
        },
        {
            name:      'name',
            sortField: 'name',
            title: 'Nombre',
            width:     '15%'
        },
        {
            name:  'type_event.name',
            title: 'Tipo de evento',
            width: '14%'
        },
        {
            name:  'start',
           title: 'Inicio',
            width: '14%'
        },
        {
            name:  'end',
            title: 'Finaliza',
            width: '14%'
        },
        {
            name:  'code',
            title: 'Código',
            width: '10%'
        },
        {
            name:       '__slot:status',
            title:      'Estatus',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            width:      "15%",
        },
        // {
        //     name:       '__slot:active',
        //     title:      'Active',
        //     titleClass: "center aligned",
        //     dataClass:  "center aligned",
        //     width:      "15%",
        // },
        {
            name:       '__slot:actions',
            title:      'Acciones',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            width:      "25%",
        },
    ],
    sortFunctions: {
        'name':     function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        },
        'location': function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        }
    }
}
