export default {
    tableFields:   [
        {
            name:      '__component:badge-column',
            title:     '',
            dataClass: 'text-center',
            width:     '4%'
        },
        {
            name:      'name',
            sortField: 'name',
            title:     'Nombre',
            width:     '24%'
        },
        {
            name:      'owner.user.name',
            title:     'Dueño',
            sortField: 'name_owner',
            width:     '20%'
        },
        {
            name:  'type_company.name',
            title: 'Tipo de empresa',
            width: '20%'
        },
        {
            name:       '__slot:active',
            title:      'Estatus',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            width:      "15%",
        },
        {
            name:       '__slot:actions',
            title:      'Acciones',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            width:      "25%",
        },
    ],
    sortFunctions: {
        'name': function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        },
    }
}
